import "@babel/polyfill";
import 'css/style.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'font-awesome/css/font-awesome.min.css';
import 'ionicons/dist/css/ionicons.min.css';
import 'admin-lte/dist/css/AdminLTE.min.css';
import 'css/skin-idealayer-light.css';

import { getSkinInfo } from './skin';

async function load() {
    const skinInfo = await getSkinInfo();
    let padding = "10px";
    if(skinInfo.logoPadding) padding = skinInfo.logoPadding;
    if (skinInfo.logoOnly) {
        document.querySelector('.login-logo').innerHTML = `<img height="33px" src="${skinInfo.logo}" style="vertical-align: baseline; height: 48px; object-fit: contain; padding: ${padding}"></img>`;
    } else {
        document.querySelector('.login-logo').innerHTML = `<img height="33px" src="${skinInfo.logo}" style="vertical-align: middle; height: 48px; object-fit: contain; padding: ${padding}"></img><span class="title">${skinInfo.logoText}</span><span class="subtitle">${skinInfo.logoSecondaryText}</span>`;
    }
    document.querySelector('.login-logo').style = `background-color: ${skinInfo.colour1}`;
    document.body.style="";
}

load();