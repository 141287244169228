
export default function fetchJSON(path) {
  return fetch(path, {
    credentials: "same-origin"
  })
    .then(
      (response) => {
        if (response.status !== 200) {
          throw new Error(`Looks like there was a problem. Status Code: ${response.status}`);
          // `Server error when fetching JSON from ${path }. Error: ${JSON.stringify(data, null, 2)}`
        }
        
        return response.json();
        // console.error(`There was an error while loading '${path}'. Perhaps check your json syntax.`);
      },
    );
}
